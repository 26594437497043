
export default {
  components: {
    'remote-js': {
      render(createElement) {
        var self = this
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src },
          on: {
            load: function() {
              // console.log('remote.vue ===> load js')
              self.$emit('load-js-finish')
            }
          }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  props: {
    jsUrl: { type: String, required: true },
    jsLoadCallBack: Function
  }
}


  import RemoteJs from './remote'
  // import google from 'google'
  
  export default {
    components: { RemoteJs },
    props: {
      mapData: {
        type: Object,
        required: true
      }
    },
    created() {},
    mounted() {
      this.loadRongJs()
      
    },
    watch: {
      mapData () {
        this.loadRongJs()
      }
    },
    updated() {},
    methods: {
      loadRongJs() {
        console.log('44444444')
        console.log(this.mapData)
        var options = {
          zoom: 15,
          center: { lat: this.mapData.latitude, lng: this.mapData.longitude },
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          language: 'en'
        }
  
        var map = new window.google.maps.Map(document.getElementById('GmapsLittlePc'), options)
        const marker = new window.google.maps.Marker({
          position: { lat: this.mapData.latitude, lng: this.mapData.longitude },
          map: map,
          title: 'Google Map',
          width: '20px',
          height: '20px'
        })
        marker.setMap(map)
  
        var infowindow = new window.google.maps.InfoWindow({
          content: this.mapData.address
        })
        marker.addListener('click', function() {
          infowindow.open(map, marker)
        })
      },
      initLocation(val) {
        this.mapData = val
        this.loadRongJs()
      }
    }
  }
  
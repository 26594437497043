
export default {
  name: 'DownloadDialog',
  props: {
    title:  {
      type: String,
      default: ''
    },
    content:  {
      type: String,
      default: ''
    },
    content2:  {
      type: String,
      default: ''
    },
    content3:  {
      type: String,
      default: ''
    },
    downloadUrl: {
      type: String,
      default: 'https://hireyapp.onelink.me/UaHO/a5o4fqt9'
    },
    qrcodePath: {
      type: String,
      default: require('~/assets/qrcode/organic_qr_code.png')
    }
  },
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    download() {
      return this.downloadUrl || 'https://hireyapp.onelink.me/UaHO/a5o4fqt9'
    },
    qrcode() {
      return this.qrcodePath || require('~/assets/qrcode/organic_qr_code.png')
    }
  },
  methods: {
    downloadBth(url) {
      window.open(url, '_blank')
    }
  }
};

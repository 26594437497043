
import {
  generateUUIdOnNode
} from '@/utils/util.js';
import {
  send as logSend
} from 'smith-log'
import QRCode from "qrcode";

export default {
  name: 'SearchResult',
  props: {
    mode: String
  },
  components: {
    QRcode: QRCode
  },
  computed: {
    pageArray() {
      const tmp = []

               
      for (let i = Math.max(this.pageNum-2, 1); i <= Math.min(Math.max(this.pageNum-2, 1) + 4, Math.ceil(this.total/this.pageSize)); i++) {
        tmp.push(i)
      }
      return tmp
    }
  },
  data() {
    return {
      jobId: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,

      listData: [],
      form: {
        "degree": [],
        "experience": [],
        "financing": [],
        "industry": [],
        "salary": [],
        "strength": [],
        "workplacePolicy": []
      },

      defaultImg: 'this.src="' + require('~/assets/img/grey_avatar.png') + '"',
      companyDefaultImg: 'this.src="' + require('~/assets/img/img_company_default@2x.png') + '"',
      selectedJob: {},
      mapData: {
        longitude: 0,
        latitude: 0,
        address: ''
      },
      selectedIndex: 0,
      reachLimit: false,
      if_default: 1,

      qs: '',
      cityId: '',
      jobType: {
        1:'Full Time',
        2:'Internship',
        5:'Contract',
      },
      showSearch: true,
      jobDetailsLoading: false
    }
  },
  async fetch() {
    if(this.mode === 'google') {
      // qs-cityID
      const queryString = this.$route.params.query
      const arr = queryString.split('-')
      let qs = arr[0] || ''
      let cityId = arr[1] || ''
      // qs=Sales&cityId=41127&source=p_ca_website&description=Arizona'
      await this.init(qs, cityId)
    } else {
      let {
        qs = '',
        cityId,
        pageNum
      } = this.$route.query
      if(pageNum !== null && pageNum !== undefined) {
        this.pageNum = pageNum
      }
      await this.init(qs, cityId)
    }

  },
  watch: {
    selectedJob: {
      handler(val) {
        if(Object.keys(val).length > 0) {
          logSend('MV', 'e_ca_website_list_job_detail', this.cardRightMV(val))
        }
      },
      immediate: true
    }
  },
  methods: {
    async init(qs = '', cityId = '') {
      console.log('qs, cityId: ', qs, cityId);
      const deviceId = generateUUIdOnNode()
      const res = await this.$api.jobseeker.search({
        qs,
        cityId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.form, deviceId)
      // console.log('res: ', res);

      if(res.data.code === 2000) {
        this.listData = res.data?.data?.list
        if (this.listData && this.listData.length > 0) {
          this.showSearch = true
        } else {
          this.showSearch = false
        }

        if(this.listData[0]) {
          this.jobDetailsLoading = true
          this.selectedIndex = 0
          const job = await this.$api.jobseeker.getJob(this.listData[0].id)
          this.selectedJob = job.data.data
          this.jobDetailsLoading = false
          // console.log('loading 1')
          this.mapData.longitude = this.selectedJob.location.longitude
          this.mapData.latitude  = this.selectedJob.location.latitude
          this.mapData.address = this.selectedJob.location.address

          this.total = res.data?.data?.totalCount
          // if (res.data?.data?.totalCount > 50) {
          //   this.total = 50
          // } else {
            
          // }
        }
      } else {
        if(res.data.code === 10002) {
          this.reachLimit = true
        }
      }
    },
    async getData(qs = '', cityId = '') {
      let pageNum = this.pageNum
      const res = await this.$api.jobseeker.search({
        qs,
        cityId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.$root.context.store.state.searchFilter.filter)
      if (this.pageNum !== pageNum) {
        return
      }
      this.listData = res.data?.data?.list
      if (this.listData && this.listData.length > 0) {
        this.showSearch = true
      } else {
        this.showSearch = false
      }

      if(res.data.code === 2000) {
        if(this.listData[0]) {
          this.jobDetailsLoading = true
          this.selectedIndex = 0
          const job = await this.$api.jobseeker.getJob(this.listData[0].id)
          this.selectedJob = job.data.data
          this.jobDetailsLoading = false
          // console.log('loading 2')
          this.mapData.longitude = this.selectedJob.location.longitude
          this.mapData.latitude  = this.selectedJob.location.latitude
          this.mapData.address = this.selectedJob.location.address

          this.total = res.data?.data?.totalCount
          // if (res.data?.data?.totalCount > 50) {
          //   this.total = 50
          // } else {
          //   this.total = res.data?.data?.totalCount
          // }
        }
      } else {
        if(res.data.code === 10002) {
          this.reachLimit = true
        }
      }
    },
    async onSelectJob(index) {
      this.jobDetailsLoading = true
      this.selectedIndex = index
      const res = await this.$api.jobseeker.getJob(this.listData[index].id)
      this.selectedJob = res.data.data
      this.jobDetailsLoading = false
      // console.log('loading 3')
      this.if_default = 0
      this.mapData = {
        longitude: this.selectedJob.location.longitude,
        latitude: this.selectedJob.location.latitude,
        address: this.selectedJob.location.address
      }

      logSend('MC', 'e_ca_website_list_job_card', {
        job_id: this.selectedJob.id,
        recruiter_id: this.selectedJob.recruiter.id,
        page_id: 'p_ca_website_search'
      })
    },
    onChange(num) {
      num = num || this.pageNum
      console.log(window.location)
      let href = new URL(window.location.href)
      href.searchParams.set('pageNum', num)
      console.log('href: ', href);
      location.href = href.toString()
      
    },
    sendMC(index) {
      const data = this.listData[index]
      logSend('MC', 'e_ca_website_start_chatting_button', {
        job_id: data.id,
        recruiter_id: data.recruiter.id,
        position: 'job card',
        page_id: 'p_ca_website_search'
      })
    },
    sendMCRight() {
      const data = this.selectedJob
      logSend('MC', 'e_ca_website_start_chatting_button', {
        job_id: data.id,
        recruiter_id: data.recruiter.id,
        position: 'job detail',
        page_id: 'p_ca_website_search',
        if_default: this.if_default
      })
    },
    sendMCLike(index) {
      const data = this.listData[index]
      logSend('MC', 'e_ca_website_save_job_button', {
        job_id: data.id,
        recruiter_id: data.recruiter.id,
        position: 'job card',
        page_id: 'p_ca_website_search'
      })
    },
    sendMCLikeRight() {
      const data = this.selectedJob
      logSend('MC', 'e_ca_website_save_job_button', {
        job_id: data.id,
        recruiter_id: data.recruiter.id,
        position: 'job detail',
        page_id: 'p_ca_website_search',
        if_default: this.if_default
      })
    },
    cardMV(data) {
      return {
        page_id: 'p_ca_website_search',
        job_id: data.id,
        recruiter_id: data.recruiter.id,
      }
    },
    cardRightMV(data) {
      return {
        page_id: 'p_ca_website_search',
        job_id: data.id,
        recruiter_id: data.recruiter.id,
        if_default: this.if_default
      }
    }
  },
  created() {
    let {
      qs = '',
      cityId,
    } = this.$route.query
    if(qs !== null && qs !== undefined) {
      this.qs = qs
    }
    if(cityId !== null && cityId !== undefined) {
      this.cityId = cityId
    }
  },
  mounted() {
    this.$root.$on('search', (qs, cityId) => {
      this.pageNum = 1
      this.total = 0
      this.if_default = 1
      if(qs !== undefined) {
        this.qs = qs
      }
      if(cityId !== undefined) {
        this.cityId = cityId
      }

      this.getData(this.qs, this.cityId)
    })

    this.$root.$on('changeQuery', (qs, cityId) => {
      this.qs = qs
      this.cityId  = cityId
    })
  },
  destroyed() {
    this.$root.$off('search')
    this.$root.$off('changeQuery')
  }
};

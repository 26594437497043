
export default {
  name: 'chattingNow',
  props: {
    jobId: {
      type: [Number, String],
    },
  },
  data()  {
    return {
      visible: false,
      title: 'Get Hirey App',
      content: 'Scan the QR code to download Hirey APP Chat with recruiter directly right now!'
    }
  },
  methods: {
    onClick() {
      // this.visible = true
      location.href = `/job/${this.jobId}`
      this.$emit('click')
    }
  }
};
